<template>
  <div>
    <el-container>
      <el-header>
        <div class="navBox">
          <el-avatar icon="el-icon-user-solid" :src="logo"></el-avatar>
          <div class="projectName">巡查蝠</div>
          <el-select v-model="value" placeholder="请选择" class="el_select" @change="selectChange" :disabled="this.$route.name == 'newProject' ? true : false">
            <el-option :label="item.label" :value="item.value" v-for="item in projectArr" :key="item.id"></el-option>
          </el-select>
          <el-menu class="el-menu-demo" mode="horizontal" background-color="#001529" text-color="#fff" active-text-color="#1890ff" @select='selectHangle' :router="true" :default-active="homePage">
            <el-menu-item index="homePage">首页</el-menu-item>
            <el-menu-item index="patrol">巡检</el-menu-item>
            <el-submenu index="2">
              <template slot="title">{{ task }}</template>
              <el-menu-item index="assignment" @click="check('普通任务')">普通任务</el-menu-item>
              <el-menu-item index="collectiveTask" @click="check('集体任务')">集体任务</el-menu-item>
              <el-menu-item index="cycleTask" @click="check('周期任务')">周期任务</el-menu-item>
            </el-submenu>
            <el-menu-item index="order">工单</el-menu-item>
            <el-menu-item index="location">点位</el-menu-item>
            <el-menu-item index="personnel">人员</el-menu-item>
            <el-menu-item index="indent">订单</el-menu-item>
          </el-menu>
          <i class="el-icon-message-solid"></i>
          <el-dropdown trigger="click" @command="handleCommand">
            <div class="flex_center_col">
              <el-avatar icon="el-icon-user-solid" :src="user_info.avatar" @error="errorHandler">
                <img :src="circleUrl" />
              </el-avatar>
              <span class="projectName">{{ user_info.name }}</span>
            </div>
            <el-dropdown-menu slot="dropdown" :split-button="true">
              <el-dropdown-item icon="el-icon-s-custom" command="toUserDetail">个人信息</el-dropdown-item>
              <el-dropdown-item icon="el-icon-info" divided command="toProjectDetail">项目信息</el-dropdown-item>
              <el-dropdown-item icon="el-icon-s-tools" command="toSetProject">项目设置</el-dropdown-item>
              <el-dropdown-item icon="el-icon-circle-plus" command="toNewProject">新建项目</el-dropdown-item>
              <el-dropdown-item divided class="color_red" command="quitProject">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-main>

        <transition name="fade" mode="out-in">
          <keep-alive include="homePage,patrol,assignment,order,location,personnel,newProject,collectiveTask,cycleTask,userDetail,indent">
            <router-view>
              <!-- 这里是会被缓存的视图组件，比如 Home！ -->
            </router-view>
          </keep-alive>
        </transition>

      </el-main>
    </el-container>
    <div class="connect">
      <span class="relative">
        <img src="@/assets/img/download.png" class="download" />
        <img src="@/assets/img/app.png" class="app"/>
      </span>

      <span class="relative">
        <span class="phone">400-8698-001</span>
        <img src="@/assets/img/phone.png" />
      </span>

      <span class="relative">
        <img src="@/assets/img/mini.jpg" class="mini" />
        <img src="@/assets/img/wechat.png" alt="" />
      </span>
    </div>
  </div>
</template>

<script>
import { account } from '../../utils/api';
export default {
  name: 'projectInfo',
  data() {
    return {
      url:
        'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      // value: "",
      logo: require('../../assets/img/patrol.png'),
      // projectArr: [], // 项目数组存放项目信息
      projectValue: '',
      value: this.$store.state.projectValue,
      task: '任务',
      homePage: 'homePage',
      circleUrl:
        'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
    };
  },
  computed: {
    projectArr() {
      return this.$store.state.projectArr;
    },
    user_info: {
      get() {
        return this.$store.state.user_info;
      },
      set(v) {
        this.countData = v;
      }
    }
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case 'toNewProject':
          this.$router.push('newProject');
          break;
        case 'toProjectDetail':
          this.$router.push('projectDetail');
          break;
        case 'toSetProject':
          this.$router.push('setProject');
          break;
        case 'toUserDetail':
          this.$router.push('userDetail');
          break;
        default:
          this.quitProjectEvent();
      }
    },
    async quitProjectEvent() {
      const res = await this.$http.get(account.logout);
      if (res.code == 0) {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('vuex');
        sessionStorage.removeItem('index');
        window.location.reload();
        this.$router.push('login');
      }
    },
    // id为项目id 然后拿项目id去遍历
    async selectChange(id) {
      this.homePage = 'homePage';
      sessionStorage.setItem('index', 'homePage');
      let current_project = this.user_info.members.find(item => {
        return item.organization_id === id;
      });
      this.$store.commit('setCurrentProject', current_project);
      this.$store.commit('setprojectValue', id);
      this.projectValue = id;
      if (this.$route.path === '/homePage') {
        this.homePage = 'homePage';
        return window.location.reload();
      }
      await this.$router.push('projectInfo');
      window.location.reload();
    },
    check(title) {
      this.task = title;
    },
    selectHangle(e) {
      sessionStorage.setItem('index', e);
    },
    errorHandler() {
      return true;
    }
  },
  // 开局初始化项目
  mounted() {
    this.user_info = this.$store.state.user_info;
    if (sessionStorage.getItem('index')) {
      this.homePage = sessionStorage.getItem('index');
    }
    if (JSON.parse(sessionStorage.getItem('vuex')).projectValue) {
      return false;
    }
    // 配置项目选项数组
    let projectArr = [];
    if (!this.user_info.members) {
      return;
    }
    this.user_info.members.forEach((item, index) => {
      let obj = {
        label: item.organization.name,
        value: item.organization.id
      };
      projectArr.push(obj);
    });
    // 并设置第一个项目为当前项目信息

    this.$store.commit('setProjectArr', projectArr);
    this.value = this.$store.state.projectArr[0].value;
    this.$store.commit(
      'setprojectValue',
      this.$store.state.projectArr[0].value
    );
    this.$store.commit('setCurrentProject', this.user_info.members[0]);
  }
};
</script>
<style lang="scss" scoped>
.el-header {
  background-color: #001529;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-container /deep/ .el-header {
  height: 80px;
}
.el-container /deep/ .el-select.el_select .el-input__inner {
  background-color: #001529;
  border: none;
  color: #fff;
  width: 120px !important;
}

.el-main {
  display: flex;
  justify-content: center;
  background-color: #e9eef3;
  color: #333;
  // text-align: center;
  min-height: 100vh;
}

body > .el-container {
  margin-bottom: 40px;
}
.navBox {
  width: 1200px;
  display: flex;
  align-items: center;
  font-size: 30px;
  color: #fff;
  min-width: 1200px;
  .projectName {
    margin: 0 20px;
  }
  .el-menu {
    display: flex;
    justify-content: space-around;
    flex: 1;
    margin-right: 50px;
  }
  i {
    margin-right: 20px;
    font-size: 20px;
  }
}
.color_red {
  color: red;
  text-align: center;
  font-weight: 700;
}
.connect {
  width: 60px;
  // height: 120px;
  // background-color: brown;
  position: fixed;
  z-index: 150;
  right: 1%;
  bottom: 20%;
  // background-color: #fff;
  // font-size: 0;
  img {
    width: 100%;
    height: 50%;
    margin: 2px 0;
    border: 2px solid #eeeeee;
    border-radius: 10px;
    background-color: #fff;
    padding: 5px;
    &:hover .mini {
      display: block;
    }
  }
  .app {
    width: 100%;
    height: 50%;
    margin: 2px 0;
    border: 1px solid #99ccff;
    border-radius: 10px;
    background-color: #fff;
    padding: 0px;
    &:hover .download {
      display: block;
    }
  }
  .download {
    width: 200px;
    height: 200px;
    position: absolute;
    left: -211px;
    top: -140px;
    display: none;
  }

  .mini {
    width: 200px;
    height: 200px;
    position: absolute;
    left: -211px;
    top: 140px;
    display: none;
  }
  .phone {
    width: 200px;
    height: 58px;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    position: absolute;
    left: -212px;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    line-height: 58px;
    top: 74px;
    border-radius: 10px;
    display: none;
  }
}
.lastSite {
  font-size: 20px;
  font-weight: 700;
}
.relative:hover .download,
.relative:hover .mini,
.relative:hover .phone {
  display: block;
}
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  width: 116px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
